import { Star } from "./Star";
import React, { useState, useCallback } from 'react';

export const StarRating = ({ onRate, theme }) => {
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);

    const handleClick = (i) => {
        if (rating === i) {
            setRating(null);
            onRate(null);
        } else {
            setRating(i);
            onRate(i);
        }
    };

    const handleMouseEnter = useCallback((i) => {
        setHoverRating(i);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoverRating(0);
    }, []);

    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <Star
                key={i}
                filled={i <= rating}
                onClick={() => handleClick(i)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
                enlarge={i <= hoverRating}
                theme={theme}
            />
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {stars}
            </div>
            {rating > 0 && <p style={{ position: 'absolute', bottom: '-5vh', fontSize: '1.5em'}}>{rating}/5</p>}
        </div>
    );
};