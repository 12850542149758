import React, { useState, useMemo, useContext, useEffect } from 'react';
import { SendButton } from '../SendButton';
import { ThemeContext } from '../../../Theme';
import { Row, Form, Button } from 'reactstrap';
import { useTranslate } from 'react-translate';
import * as State from '../State';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'reactstrap';

import { FileImage } from './FileImage';
import { FileIcon } from './FileIcon';
import { DeleteIcon } from './DeleteIcon';
import { SuccessIcon } from './SuccessIcon';

import './UploadFileActivity.css';

const MAXSIZE_MO = 200;
const MAXSIZE_BYTE = MAXSIZE_MO * 1048576;
const BYTE_MULTIPLIER = 1048576;

function wrongFormatMessage(file, t) {
    let ext = '';
    if (file && file.name)
        ext = `(*.${getExtension(file.name)})`;

    return t('file.wrongFormat') + ext;
}

function InputErrors(props) {
    let message;

    if (props.fileRejections.length === 0) {
        // if file is accepted by Input MimeTypes but has not a valid extension
        if (props.acceptedFiles[0] && !hasValidExtension(props.acceptedFiles[0], props.activityArgs))
            message = wrongFormatMessage(props.acceptedFiles[0], props.t);
        else if (props.acceptedFiles[0] && !hasValidSize(props.acceptedFiles[0], props.activityArgs))
            message = `${props.t('file.tooLarge')} (Max ${props.activityArgs.maxFileSize / BYTE_MULTIPLIER} Mo)`;
        else
            return null;
    }
    else {
        const rejection = props.fileRejections[0]
        const error = rejection.errors[0];
        console.log(error.code);
        if (error.code === "file-too-large")
            message = `${props.t('file.tooLarge')} (Max ${MAXSIZE_MO} Mo)`;
        else if (error.code === "too-many-files")
            message = props.t('file.tooMany');
        else
            message = wrongFormatMessage(rejection.file, props.t);
    }

    return <p className="inputError">{message}</p>;
}

function getInputMimeTypes(args) {
    if (args && args.allowedMimeTypes && args.allowedMimeTypes.length > 0)
        return args.allowedMimeTypes.join();
    else
        return '.jpg,.jpeg,.png,.webp,.pdf,.pptx,.mp4';
}

function hasValidExtension(file, activityArgs) {
    if (file && file.name) {
        if (!activityArgs || !activityArgs.allowedExtensions) {
            return true;
        }
        else {
            const ext = ("." + getExtension(file.name)).toLowerCase();
            return !!(activityArgs.allowedExtensions.find(e => e === ext));
        }
    }

    return false;
}

function hasValidSize(file, activityArgs) {
    if (file && file.name) {
        if (!activityArgs || !activityArgs.maxFileSize) {
            return true;
        } else {
            return (file.size < activityArgs.maxFileSize)
        }
    }

    return false;
}

function getExtension(fileName) {
    return fileName.split('.').pop();
}

function limitFileNameChars(input) {
    const max = 40;
    if (input.length <= max)
        return input;

    const ext = getExtension(input);
    return input.slice(0, max - 3 - ext.length) + ".. ." + ext;
}


export function UploadFileActivityUI(props) {
    const t = useTranslate('Upload');
    const common = useTranslate('Common');
    const theme = useContext(ThemeContext);
    const [state, setState] = useState(State.ready);
    const [ignoreFile, setIgnoreFile] = useState(false);
    const [uploadResult, setUploadResult] = useState(undefined);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { acceptedFiles, fileRejections, isDragActive,  getRootProps, getInputProps } = useDropzone(
    {
        accept: getInputMimeTypes(props.activityArgs),
        multiple: false,
        preventDropOnDocument: false,
        maxSize: MAXSIZE_BYTE,
        onDrop: _ => {
            setIgnoreFile(false);
            setUploadResult(undefined);
        },
        disabled: state === State.sending
    });
    
    const style = useMemo(() => {
        const baseStyle = { borderColor: theme.baseColor };
        const activeStyle = { borderColor: 'white', backgroundColor: theme.baseColor };
        return isDragActive ? activeStyle : baseStyle;
    }, [isDragActive, theme]);

    const {
        callHubAsync,
    } = props;
    const serviceVersion = props.serviceVersion;

    let file;
    // Check extension after Input's built-in check to deal with deltas between Extensions and MimeTypes
    // (MimeTypes are more permissive, but needed for Safari)
    if (acceptedFiles && hasValidExtension(acceptedFiles[0], props.activityArgs) && hasValidSize(acceptedFiles[0], props.activityArgs))
        file = acceptedFiles[0];

    const hasFile = !!file && !ignoreFile;

    useEffect(() => {
        if (state === State.sending) {
            let uploadUri = null;
            props.getUploadUriAsync(file.type)
                .then(uploadUriResponse => {
                    uploadUri = uploadUriResponse;
                    alertUploadingFile(uploadUri.fileId, file.name);
                    let uploadRequest = props.sendFileAsync(uploadUri.uri, file, setUploadProgress);
                    return uploadRequest.promise
                })
                .then(uploadResponse => {
                    console.log("upload ended succesfully");
                    return props.getDownloadUriAsync(uploadUri.fileId);
                })
                .then(downloadUriResponse => {
                    console.log("downloadUri received");
                    alertUploadedFile(downloadUriResponse.uri, downloadUriResponse.fileId, file.name, file.size);
                    setIgnoreFile(true);
                    setUploadResult({ success: true });
                    setState(State.ready);
                })
                .catch(error => {
                    if (uploadUri !== undefined) {
                        alertUploadingFileCanceled(uploadUri.fileId);
                    }
                    if (error !== undefined && error.type === 'abort') {
                        console.log("upload canceled");
                        setIgnoreFile(true);
                        setState(State.ready);
                    }
                    else {
                        console.log("upload failed");
                        console.log(error);
                        setUploadResult({
                            success: false,
                            message: error.target.response
                        });
                        setState(State.error);
                    }
                });
        }
        return undefined;
    }, [state, file, props.sendFileAsync, setUploadProgress, setIgnoreFile, setUploadResult, setState]);

    async function alertUploadedFile(uri, fileId, name, size) {
        var fileInfos = {
            uri: uri,
            fileId: fileId,
            name: name,
            size: size
        };
        await callHubAsync("addFile", fileInfos);
    }

    async function alertUploadingFile(fileId, name) {
        if (serviceVersion === "1.0")
            return;
        var infos = {
            fileId: fileId,
            name: name
        };
        await callHubAsync("uploadingFile", infos);
    }
    async function alertUploadingFileCanceled(fileId) {
        if (serviceVersion === "1.0")
            return;
        var infos = {
            fileId: fileId
        };
        await callHubAsync("uploadCancelled", infos);
    }


    function sendFile(stateWhenClick, event) {
        event.preventDefault();

        if (stateWhenClick == State.sending) {
            props.onCancel();
            setState(State.ready);
        } else {
            if (!hasFile)
                return;

            setUploadProgress(0);
            setState(State.sending);
        } 
    }

    function onDelete(event) {
        setIgnoreFile(true);
        event.stopPropagation();
    }

    return (<>
        <Form>
            <Row className="justify-content-center" >
                <div className="dropZone col" {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {hasFile &&
                        <div className="fileInfos">
                            <FileIcon className="hide-when-super-small" fileName={file.name} />
                        <p>{limitFileNameChars(file.name)}</p>
                            <Button className="deleteButton themedButton" onClick={onDelete} style={{ backgroundColor: theme.baseColor }} disabled={state === State.sending}>
                                <DeleteIcon />
                            </Button>
                        </div>
                    }
                    {!hasFile &&
                        <>
                            <FileImage />
                            <p>{t('instructions')}</p>
                        </>
                    }
                </div>
            </Row>

            {!ignoreFile &&
                <InputErrors {... { acceptedFiles, fileRejections, t }} activityArgs={props.activityArgs} />
        }

            <SendButton
                state={state}
                progress={uploadProgress}
                onClick={(stateWhenClick, event) => sendFile(stateWhenClick, event)}
                disabled={!hasFile}
                cancellable={true}
            />
            {state == State.sending && (<>
                <Row className="justify-content-center align-items-center mt-2" >
                    <Spinner color="light" size="sm" />
                    {uploadProgress !== undefined && <span className="progressCount">{uploadProgress} %</span>}
                </Row>
            </>)}

            {uploadResult !== undefined &&
                <>
                    <Row className="justify-content-center align-items-center mt-2" >
                        <SuccessIcon success={uploadResult.success} />
                        <p className="uploadMessage">{uploadResult.success ? t('success') : common('error.retry')}</p>
                    </Row>
                    {!uploadResult.success &&
                        <p className="errorDetails">{uploadResult.message}</p>
                    }
                </>
            }
        </Form>
    </>);
}