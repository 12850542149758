import React, { useRef, useState } from "react";
import { NavigationBar } from "./NavigationBar";
import { Spinner } from 'reactstrap';
import { ToolMenu } from "./ToolMenu";
import { types, defaultTool } from './Tools';
import { ZoomPanPitchDraw } from "./ZoomPanPitchDraw";
import { useTranslate } from "react-translate";
import "./Presenter.css"

export function DocumentPresenter(props) {
    const {
        pages,
        initialPageIndex,
        callHubAsync,
        alertPageChangedAsync,
        paletteColors,
        setTitleClassName,
        isTimerRunning,
        setError
    } = props;

    const t = useTranslate("Presenter");
    const [currentPageIndex, setCurrentPageIndex] = useState(initialPageIndex);
    const [tool, setTool] = useState(defaultTool);
    const [annotations, setAnnotations] = useState([]);
    const [stylusDetected, setStylusDetected] = useState(false);
    const surfaceRef = useRef();
    const [surfaceHeight, setSurfaceHeight] = useState(window.innerHeight);

    function changePage(index) {
        setCurrentPageIndex(index);
        if (pages[index]) {
            setAnnotations(pages[index].annotations);
        }
        alertPageChangedAsync(index, 0);
    }

    function addLines(lines) {
        setAnnotations((prev) => {
            var newValue = prev.concat(lines);
            pages[currentPageIndex].annotations = newValue;

            callHubAsync("pageChanged",
            {
                strokes: lines.map((l) =>
                ({
                    id: l.id,
                    pageIndex: currentPageIndex,
                    color: l.brushColor,
                    size: l.brushRadius,
                    width: l.width,
                    height: l.height,
                    points: l.points.map((p) => ({ X: p.x, Y: p.y })),
                }))
            });

            return newValue;
        });
    }

    function removeLines(lines) {
        setAnnotations((prev) => {
            var newValue = prev.filter((l) => !lines.some((r) => l.id === r.id));
            pages[currentPageIndex].annotations = newValue;
            callHubAsync(
                "pageChanged",
                {
                    deletedStrokes: lines.map((l) =>
                    ({
                        Id: l.id,
                        PageIndex: currentPageIndex,
                    }))
                }
            );
            return newValue;
        });
    }

    if (pages && pages[initialPageIndex] && pages[initialPageIndex].ready) {
        let currentPage = pages[currentPageIndex];
        setTitleClassName('presenter-title');

        return (
            <div
                className='main-container'
                ref={surfaceRef}
                style={{ height: surfaceHeight + 'px' }}
            >
                    {currentPage && currentPage.ready
                        ? (<div className='zppd-container'>
                        <ZoomPanPitchDraw
                            pan={tool.type === types.HAND}
                            pen={{
                                size: tool.size,
                                color: tool.color,
                                eraser: tool.type === types.ERASER,
                            }}
                            imageSource={currentPage.source}
                            {...{ annotations, addLines, removeLines, stylusDetected, setSurfaceHeight, setError }}
                        />
                    </div>)
                    : (<div className="loading">
                        <span>{t("presentation.pageLoading")}</span>
                        <Spinner color="light" className='spinner' />
                        {!isTimerRunning ? <span>{t("presentation.pageLoadingTooLong")}</span> : <></>}
                    </div>)
                    }
                    <ToolMenu {...{ tool, setTool, surfaceRef, setStylusDetected, paletteColors }} />
                <div className='bottom'>
                    <NavigationBar
                        {...{ currentPageIndex, changePage }}
                        pageCount={pages?.length}
                    />
                </div>

                </div>
        );
    } else {
        return (
            <div className="loading">
                <h4>{t("presentation.started")}</h4>
                <span>{t("presentation.pageLoading")}</span>
                <Spinner color="light" className='spinner' />
                {!isTimerRunning ? <span>{t("presentation.pageLoadingTooLong")}</span> : <></>}
            </div>
        );
    }
}
