import React, { useState, useEffect } from 'react';
import { ToolFloatingButton } from './ToolFloatingButton';
import { ToolPalette } from './ToolPalette'
import './Tools.css';

export function ToolMenu(props) {

    const { tool, setTool, surfaceRef, setStylusDetected, paletteColors } = props;
    const [paletteIsOpen, setPaletteIsOpen] = useState(false);
    // array of elements : {color, row, col}
    const [colorsArgs, setColorsArgs] = useState([]);
    const [colorGridRow, setColorGridRow] = useState(3);
    const [colorGridCol, setColorGridCol] = useState(3);

    function constructColorArgs(colorTab) {
        var tab = [];
        var length = colorTab.length;
        var index = 0;
        var row = 3;
        var col = 3;

        if (length > 9) {
            col = 4;
        }
        if (length > 12) {
            row = 4;
        }
        for (var i = 1; i <= row; i++) {
            for (var j = 1; j <= col; j++) {
                if (index < length) {
                    tab[index] = { color: colorTab[index], row: i, col: j };
                    index++;
                }
            }
        }
        setColorGridCol(col);
        setColorGridRow(row);
        setColorsArgs(tab);
    }

    useEffect(() => {
        if (paletteColors) {
            constructColorArgs(paletteColors);
        } else {
            constructColorArgs(["#f4d000", "#fcfcfc", "#00c5d5", "#f45b00", "#000000", "#0055d5", "#ff0000", "#e43b5c", "#008000"]);
        }
    }, []);

    function setToolType(newType) {
        setTool(previousTool => {
            var newTool = { ...previousTool };
            newTool["type"] = newType;
            return newTool;
        });
    }

    return (<>
        { paletteIsOpen
            ? (<>
                <ToolPalette {...{ tool, setTool, setPaletteIsOpen, colorsArgs, colorGridCol, colorGridRow }} />
            </>) : (<></>)
        }
        <div className='fab-container not-display-if-desktop'>
            <ToolFloatingButton {...{ paletteIsOpen, setPaletteIsOpen, tool, setToolType, surfaceRef, setStylusDetected }} />
        </div></>
    )
}