import React, { useEffect, useRef, useState} from 'react';
import { useTranslate } from 'react-translate';
import * as Activities from './Activities';

export function ActivityRenderer(props) {
    const t = useTranslate('ActivityManager');
    const { activity, projectName, customActivityName, setFooterClassName, addFooterClassName, ...innerProps } = props;
    const titleRef = useRef(null);

    useEffect(() => {
        document.title = "Compositeur Digital UX - " + projectName;
    }, [projectName]);

    function Activity() {
        switch (activity) {
            case Activities.Note:
                return <Activities.NoteActivity 
                    colorSeed={innerProps.colorSeed} 
                    callHubAsync={innerProps.callHubAsync}
                    serviceVersion={innerProps.serviceVersion}
                />;
            case Activities.UploadFile:
                return <Activities.UploadFileActivity
                    activityArgs={innerProps.activityArgs}
                    sessionId={innerProps.sessionId}
                    getUploadUriAsync={innerProps.getUploadUriAsync}
                    getDownloadUriAsync={innerProps.getDownloadUriAsync}
                    userId={innerProps.userId}
                    connectionId={innerProps.connectionId}
                    callHubAsync={innerProps.callHubAsync}
                    serviceVersion={innerProps.serviceVersion}
                    setCancelUpload={props.setCancelUpload}
                />;
            case Activities.DownloadFiles:
                return <Activities.DownloadFilesActivity
                    userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    serviceVersion={innerProps.serviceVersion}
                    connectionId={innerProps.connectionId}
                    filesArgs={props.activityArgs.files}
                    theme={innerProps.theme}
                />
            case Activities.Presenter:
                return <Activities.PresenterActivity
                    activityArgs={innerProps.activityArgs}
                    sessionId={innerProps.sessionId}
                    connectionId={innerProps.connectionId}
                    userId={innerProps.userId}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    callHubAsync={innerProps.callHubAsync}
                    titleRef={titleRef}
                    setFooterClassName={setFooterClassName}
                    addFooterClassName={addFooterClassName}
                    serviceVersion={innerProps.serviceVersion}
                />;
            case Activities.Vote:
                return <Activities.VoteActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    choicesArgs={props.activityArgs.choices}
                    serviceVersion={innerProps.serviceVersion}
                    theme={innerProps.theme}
                />
            case Activities.LikeDislike:
                return <Activities.LikeDislikeActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    theme={innerProps.theme}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    serviceVersion={innerProps.serviceVersion}
                />
            case Activities.TrueFalse:
                return <Activities.TrueFalseActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    theme={innerProps.theme}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    serviceVersion={innerProps.serviceVersion}
                />
            case Activities.YesNo:
                return <Activities.YesNoActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    theme={innerProps.theme}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    serviceVersion={innerProps.serviceVersion}
                />
            case Activities.Rating:
                return <Activities.RatingActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    theme={innerProps.theme}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    serviceVersion={innerProps.serviceVersion}
                />
           
            default: {
                return <p>{t('session.noActivity')}</p>;
            }
                
        }
    }

    const getActivityTitle = () => {
        if (props.customActivityName) return props.customActivityName;

        if (props.activity) {
            return t('legacy.' + props.activity.toLowerCase() + '.title');
        }

        return '';
    };

    return (<>
        <h2 ref={titleRef}>{getActivityTitle()}</h2>
        <Activity />
    </>);
    
}