import React from 'react';
import { useParams } from 'react-router-dom';
import { ActivityManager } from './ActivityManager';
import { useTranslate } from 'react-translate';
import { Logo } from './Logo';
import { version } from "../../package.json";

export function Home(props) {
    const t = useTranslate('Home');
    const { sessionId } = useParams();
    const { footerRef } = props;

    if (sessionId) {
        return <ActivityManager sessionId={sessionId.toLowerCase()} {... { footerRef }} />;
    } else {
        window.location.href = 'https://www.compositeurdigital.com';
        return (
            <>
                <Logo maxSize={150} />
                <h2>{t('title')}</h2>
                <p>{t('instructions.redirect')}</p>

                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
            </>
        );
    }
}