import React, { useState } from 'react';
import { Form, Input } from 'reactstrap';
import { ThemedButton } from './ThemedButton';
import { useTranslate } from 'react-translate';

export function LoginForm(props) {
    const t = useTranslate('Login');
    const [userName, setUserName] = useState('');

    const isValid = userName && userName.length > 2;

    function join(event) {
        event.preventDefault();
        if (isValid) {
            props.joinSessionAsync(userName);
        }
    }

    return (
        <Form className="inlineInput">
            <Input
                type="text"
                value={userName}
                onChange={e => setUserName(e.target.value)}
                placeholder={t('name.full')}
                aria-label={t('name.short')}
                id="username"
                name="name"
                autoComplete="name"
            />
            <ThemedButton type="submit" content={t('join')} onClick={join} disabled={!isValid} />
        </Form>
    );
}