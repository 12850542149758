import React, { useState, useEffect } from 'react';
import { StarRating } from "./Criteria";
import { useTranslate } from 'react-translate';
import * as State from '../State';
import './RatingActivity.css';

const inDuration = 500;
const outDuration = 10;
const delayBetweenRates = 10;

export function RatingActivity(props) {
    const common = useTranslate('Common');
    const [state, setState] = useState('init');
    const [rate, setRate] = useState('');
    const { callHubAsync, theme } = props;

    useEffect(() => {
        if (state === State.sending) {
            async function sendAsync() {
                try {
                    const result = await callHubAsync("addRate", { value: rate });
                    if (result) {
                        await new Promise(r => setTimeout(r, outDuration + delayBetweenRates));
                        setRate(undefined);
                        setState(State.ready);
                    }
                    else {
                        setState('error');
                        console.error("failed to send rate");
                    }
                }
                catch (error) {
                    setState('error');
                    console.error(error);
                }
            }
            sendAsync();
        }
    }, [state, callHubAsync]);

    function sendMessage(selectedRating) {
        setRate(selectedRating);
        setState(State.sending);
    }

    return (
        <form>
            <StarRating onRate={sendMessage} theme={theme} />
            <p style={{ opacity: state === 'error' ? 1 : 0 }}>
                {common('error.retry')}
            </p>
        </form>
    );
}