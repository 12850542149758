import React from 'react';
import { Layout } from './components/Layout';
import { Translator } from './components/Translator';

import './custom.css';

const App = () => {

    return (
        <Translator>
            <Layout />
        </Translator>
    );
};

export default App;
