export const Note = 'Note';
export const UploadFile = 'UploadFile';
export const DownloadFiles = 'DownloadFiles';
export const Presenter = 'Presenter';
export const Vote = 'Vote';
export const LikeDislike = 'LikeDislike';
export const Rating = 'Rating';
export const TrueFalse = 'TrueFalse';
export const YesNo = 'YesNo';

export { NoteActivity } from './Note/NoteActivity';
export { UploadFileActivity } from './UploadFile/UploadFileActivity';
export { DownloadFilesActivity } from './DownloadFiles/DownloadFilesActivity';
export { PresenterActivity } from './Presenter/PresenterActivity';
export { VoteActivity } from './Vote/VoteActivity';
export { LikeDislikeActivity } from './LikeDislike/LikeDislikeActivity';
export { RatingActivity } from './Rating/RatingActivity';
export { TrueFalseActivity } from './TrueFalse/TrueFalseActivity';
export { YesNoActivity } from './TrueFalse/TrueFalseActivity';