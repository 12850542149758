import React from 'react';
import './Star.css'

export const Star = ({ filled, theme, onClick, onMouseEnter, onMouseLeave, enlarge }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={filled ? theme.lightColor : "none"} stroke={filled ? "none" : theme.lightColor}
            onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
            style={{ transform: enlarge ? 'scale(1.2)' : 'none' }} className="star">
            <path d="M12 2l3.09 6.26L22 9.27l-5 4.88L18.18 22 12 18.7 5.82 22 7 14.15l-5-4.88 6.91-1.01L12 2z" />
        </svg>
    );
};