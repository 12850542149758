import React, { useState, useEffect } from "react";
import { DocumentDownloader } from "./DocumentDownloader";
import * as PresenterState from "./PresenterState";
import { useTranslate } from "react-translate";
import './Presenter.css'
import { ErrorDisplayer } from "../../Errors/ErrorDisplayer";

export function PresenterActivity(props) {
    const t = useTranslate("Presenter");
    const { activityArgs, userId, subscribeToEvent, titleRef, setFooterClassName, addFooterClassName, ...otherProps } = props;
    const [presenterActivityArgs, setPresenterActivityArgs] = useState(undefined);
    const [currentPresenters, setCurrentPresenters] = useState(activityArgs.presenters);
    const [presenterState, setPresenterState] = useState(PresenterState.waiting);
    const [error, setError] = useState(null);

    function clearTitleClassName(){
        if (titleRef && titleRef.current) {
            titleRef.current.classList = "";
        }
    }

    function setTitleClassName(name) {
        if (titleRef && titleRef.current) {
            titleRef.current.classList.add(name);
        }
    }

    function setDocumentName() {
        if (titleRef && titleRef.current && presenterActivityArgs && presenterActivityArgs.documentName) {
            const docName = presenterActivityArgs.documentName.trim();
            if (docName !== "") {
                const name = document.createTextNode(" - " + docName);
                titleRef.current.appendChild(name);
            } 
        }
    }
    
    useEffect(() => {
        if (subscribeToEvent) {
            var eventName = "startPresentation";

            var unsubscriber = subscribeToEvent(eventName, (args) => {
                var data = JSON.parse(args);
                if (data.presenterId === userId) {
                    setPresenterActivityArgs(data);
                    setPresenterState(PresenterState.presenting);
                } else if (presenterState !== PresenterState.presenting) {
                    setPresenterState(PresenterState.waiting);
                }
            });

            return unsubscriber;
        }
        return undefined;
    }, [presenterState]);

    useEffect(() => {
        if (subscribeToEvent) {
            var eventName = "stopPresentation";

            var unsubscriber = subscribeToEvent(eventName, (args) => {
                var data = JSON.parse(args);
                setPresenterActivityArgs(data);
                if (data.presenterId === userId) {
                    setPresenterState(PresenterState.waiting);
                }
            });
            return unsubscriber;
        }
        return undefined;
    }, [presenterState]);

    useEffect(() => {
        if (subscribeToEvent) {
            var eventName = "presentersChanged";

            var unsubscriber = subscribeToEvent(eventName, (args) => {
                var data = JSON.parse(args);
                if (presenterState !== PresenterState.presenting) {
                    setCurrentPresenters(data.presenters);
                }
            });
            return unsubscriber;
        }
        return undefined;
    }, [presenterState]);

    function Presenter() {
        switch (presenterState) {
            case PresenterState.waiting:
                setFooterClassName('logoContainer');
                clearTitleClassName();
                if (!currentPresenters || currentPresenters.length === 0) {
                    return <p>{t("instructions")}</p>;
                } else if (currentPresenters.length === 1) {
                    return <p><b>{currentPresenters[0].presenterName}</b> {t("presentation.otherpresenter")}</p>;
                } else {
                    if (currentPresenters.length === 2) {
                        return <p><b>{currentPresenters.map(p => p.presenterName).join(" et ")}</b> {t("presentation.otherpresenters")}</p>;
                    }
                    else {
                        return <p><b>{currentPresenters.slice(0, currentPresenters.length - 1).map(p => `${p.presenterName}`).join(", ")}</b> et <b>{currentPresenters[currentPresenters.length - 1].presenterName}</b> {t("presentation.otherpresenters")}</p>;
                    }
                }
            case PresenterState.presenting:
                if (error) {
                    setFooterClassName('logoContainer');
                    clearTitleClassName();
                    return <ErrorDisplayer message={error} />;
                } else {
                    addFooterClassName('not-display-if-mobile');
                    setDocumentName();
                    return <DocumentDownloader
                        pageCount={presenterActivityArgs.pageCount}
                        startPage={presenterActivityArgs.startPage}
                        paletteColors={presenterActivityArgs.paletteColors}
                        subscribeToEvent={subscribeToEvent}
                        setTitleClassName={setTitleClassName}
                        setError={setError}
                        {...otherProps}
                    />;
                }
            case PresenterState.ready:
            default:
                clearTitleClassName();
                setFooterClassName('logoContainer');
                return <p>{t("instructions")}</p>;    
        }
    }

    return (
        <>
            <Presenter />
        </>
    );
}
