import React, { useEffect } from 'react';
import { fetchWithProgress } from '../../../utils/Fetch';
import { UploadFileActivityUI } from './UploadFileActivityUI';


export function UploadFileActivity(props) {

    let currentUploadCancel;

    useEffect(() => {
        if (props.setCancelUpload) {
            props.setCancelUpload(cancelUpload);
        }

        return () => {
            if (props.setCancelUpload) {
                props.setCancelUpload(null);
            }
        }
    }, [props.setCancelUpload]);

    function sendFileAsync(uri, file, onUploadProgress) {
        const { promise, cancel } = new fetchWithProgress(uri, {
            method: 'PUT',
            body: file,
            headers: {
                "x-ms-blob-type": "BlockBlob",
                "content-disposition": "inline, size=" + file.size + ";",
                "x-ms-blob-content-type": file.type,
                "x-ms-blob-content-disposition": "inline, size=" + file.size + ";"
            },
            onProgress: e => {
                onUploadProgress(Math.round(100 * e.loaded / e.total));
            }
        });

        // Stocker la fonction d'annulation pour un usage ultérieur
        currentUploadCancel = cancel;

        return {promise, cancel}
    }

    function cancelUpload() {
        if (currentUploadCancel) {
            currentUploadCancel();
            currentUploadCancel = null;
        } 
    }

    return <UploadFileActivityUI
        activityArgs={props.activityArgs}
        sendFileAsync={sendFileAsync}
        getUploadUriAsync={props.getUploadUriAsync}
        getDownloadUriAsync={props.getDownloadUriAsync}
        callHubAsync={props.callHubAsync}
        onCancel={cancelUpload}
        serviceVersion={props.serviceVersion}
    />
}