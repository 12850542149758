import { useState, useEffect } from 'react';

export function useSetTimeout(delay, callback = () => { }) {
    const [isRunning, setIsRunning] = useState(true)
    useEffect(() => {
        let timerId
        if (isRunning) {
            // returns a positive interger ID which identifies the timer
            timerId = setTimeout(() => {
                callback()
                setIsRunning(false)
            }, delay)
        }
        // clear the timer
        return () => clearTimeout(timerId)
    }, [delay, callback, isRunning])
    return [isRunning, setIsRunning]
}